import React from "react";
import { useSelector } from "react-redux";

import { useLanguage } from "./LanguageContext";

import { useAppDispatch } from "../reducers";

import { TbaseReducer } from "../types/reducersType";

import ScoreA from "../images/scores/ScoreA";
import ScoreB from "../images/scores/ScoreB";
import ScoreC from "../images/scores/ScoreC";
import ScoreD from "../images/scores/ScoreD";
import ScoreE from "../images/scores/ScoreE";

import { getValue } from "../utils/general";

interface EndStepProps {}

const EndStep: React.FC<EndStepProps> = ({}) => {
  const dispatch = useAppDispatch();
  const { t } = useLanguage();

  const { score, total, gainPRE, gainR } = useSelector(
    (state: TbaseReducer) => state.analysisReducer
  );

  let ScoreImage = <></>;

  if (score > 50) {
    ScoreImage = <ScoreA />;
  } else if (score > 40) {
    ScoreImage = <ScoreB />;
  } else if (score > 30) {
    ScoreImage = <ScoreC />;
  } else if (score > 20) {
    ScoreImage = <ScoreD />;
  } else if (score !== null) {
    ScoreImage = <ScoreE />;
  }

  return (
    <div
      className="form"
      style={{ display: "flex", flexDirection: "column", gap: 20 }}
    >
      <h2>{t("result")}</h2>
      <div
        style={{
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "solid 2px #d9534f",
          borderRadius: 6,
          padding: 20,
          gap: 10,
        }}
      >
        <span style={{ fontSize: 24, fontWeight: "bold" }}>
          {getValue(total)}
        </span>
        <span style={{ fontSize: 14 }}>kg CO2e</span>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          gap: 20,
        }}
      >
        <div
          style={{
            position: "relative",
            flexDirection: "column",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "solid 2px #0c8540",
            borderRadius: 6,
            padding: 20,
            gap: 10,
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              position: "absolute",
              right: 8,
              top: 8,
              height: 24,
              width: 24,
              cursor: "pointer",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() =>
              alert(
                "Le gain PRE représente la réduction des émissions de CO2e obtenue grâce à l'utilisation de pièces de réemploi plutôt qu'à leur remplacement par des pièces neuves."
              )
            }
          >
            <span>ℹ️</span>
          </div>
          <span style={{ fontSize: 24, fontWeight: "bold" }}>
            {getValue(gainPRE)}
          </span>
          <span style={{ fontSize: 14 }}>gain PRE kg CO2e</span>
        </div>
        <div
          style={{
            position: "relative",
            flexDirection: "column",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "solid 2px #0c8540",
            borderRadius: 6,
            padding: 20,
            gap: 10,
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              position: "absolute",
              right: 8,
              top: 8,
              height: 24,
              width: 24,
              cursor: "pointer",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() =>
              alert(
                "Le gain de réparation représente la réduction des émissions de CO2e obtenue grâce à la réparation des pièces plutôt qu'à leur remplacement par des pièces neuves."
              )
            }
          >
            <span>ℹ️</span>
          </div>
          <span style={{ fontSize: 24, fontWeight: "bold" }}>
            {getValue(gainR)}
          </span>
          <span style={{ fontSize: 14 }}>gain réparation kg CO2e</span>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {ScoreImage}
      </div>
      <div className="navigation-buttons">
        <button
          className="back-button"
          onClick={() => dispatch({ type: "BACK_STEP" })}
        >
          {t("back")}
        </button>
      </div>
    </div>
  );
};

export default EndStep;
