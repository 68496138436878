// @ts-ignore
import CryptoJS from "crypto-js";
const secretKey: string = "aFqyXQmU9nkwqJlNkYlWpGjZglGXvpQs";

// Fonction pour décrypter les données
function decryptData(ciphertext: string): string {
  const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  return JSON.parse(decryptedData);
}

export const _fetch = (
  url: string,
  type: string,
  body: string,
  dispatch: any,
  method = "GET"
) => {
  return fetch(url, {
    method: method,
    headers: {
      "Content-Type": "application/json",
    },
    ...(method === "GET" ? {} : { body }),
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      let result = { type, ...res, body: JSON.parse(body) };

      result.data = decryptData(result.data);

      dispatch(result);
    })
    .catch((err) => {
      console.log("API failed");
      console.log("Une erreur est survenue");
      console.log(err);
    });
};
