import React, { ChangeEvent } from "react";
import { Container, Row, Col } from "react-grid-system";
import { useSelector } from "react-redux";

import { useLanguage } from "./LanguageContext";

import { useAppDispatch } from "../reducers";

import { getResult } from "../actions/analysisAction";

import { TbaseReducer } from "../types/reducersType";

interface UserData {
  address: string;
  city: string;
  postalCode: string;
  agree: boolean;
}

interface TranslationProps {
  id: string;
}

const Translation: React.FC<TranslationProps> = ({ id }) => {
  const { t } = useLanguage();

  return <span dangerouslySetInnerHTML={{ __html: t(id) }} />;
};

interface UserDataStepProps {
  repairMethods: { [part: string]: string };
  repairLocation: {
    name: string;
    address: string;
    city: string;
    postalCode: string;
  };
  setRepairLocation: (location: {
    name: string;
    address: string;
    city: string;
    postalCode: string;
  }) => void;
  userData: UserData;
  setUserData: (data: UserData) => void;
}

const UserDataStep: React.FC<UserDataStepProps> = ({
  repairMethods,
  repairLocation,
  setRepairLocation,
  userData,
  setUserData,
}) => {
  const dispatch = useAppDispatch();

  const {
    selectedBrand,
    selectedModel,
    selectedYear,
    selectedFuel,
    selectedParts,
    weight,
    emission,
  } = useSelector((state: TbaseReducer) => state.analysisReducer);

  const { t } = useLanguage();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = e.target;
    setUserData({ ...userData, [name]: type === "checkbox" ? checked : value });
  };

  const handleLocationChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setRepairLocation({ ...repairLocation, [name]: value });
  };

  const handleSubmit = async () => {
    const formData = {
      vehicle: {
        brand: selectedBrand,
        model: selectedModel,
        year: selectedYear,
        fuel: selectedFuel,
        weight: parseInt(weight.replace(/ /g, "")),
        emission: parseInt(emission.replace(/ /g, "")),
      },
      parts: selectedParts.map((part) => ({
        id: part,
        repairMethod: repairMethods[part],
      })),
      repairLocation,
      user: userData,
    };

    dispatch(getResult(formData));
    dispatch({ type: "NEXT_STEP" });
  };

  return (
    <div className="form">
      <div className="data">
        <h2>{t("repairLocation")}</h2>
        <Row>
          <Col xs={12}>
            <label htmlFor="locationAddress">{t("locationAddress")}</label>
            <input
              type="text"
              id="locationAddress"
              name="address"
              value={repairLocation.address}
              onChange={handleLocationChange}
            />
          </Col>
          <Col xs={4} md={4}>
            <label htmlFor="locationPostalCode">
              {t("locationPostalCode")}
            </label>
            <input
              type="text"
              id="locationPostalCode"
              name="postalCode"
              value={repairLocation.postalCode}
              onChange={handleLocationChange}
            />
          </Col>
          <Col xs={8} md={8}>
            <label htmlFor="locationCity">{t("locationCity")}</label>
            <input
              type="text"
              id="locationCity"
              name="city"
              value={repairLocation.city}
              onChange={handleLocationChange}
            />
          </Col>
        </Row>
      </div>
      <div className="data">
        <h2>{t("myAddress")}</h2>
        <Row>
          <Col xs={12}>
            <label htmlFor="address">{t("address")}</label>
            <input
              type="text"
              id="address"
              name="address"
              value={userData.address}
              onChange={handleChange}
            />
          </Col>
          <Col xs={4} md={4}>
            <label htmlFor="postalCode">{t("postalCode")}</label>
            <input
              type="text"
              id="postalCode"
              name="postalCode"
              value={userData.postalCode}
              onChange={handleChange}
            />
          </Col>
          <Col xs={8} md={8}>
            <label htmlFor="city">{t("city")}</label>
            <input
              type="text"
              id="city"
              name="city"
              value={userData.city}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <label>
          <input
            type="checkbox"
            name="agree"
            checked={userData.agree}
            onChange={handleChange}
          />
          <Translation id="agree" />
        </label>
      </div>
      <div className="navigation-buttons">
        <button
          className="back-button"
          onClick={() => dispatch({ type: "BACK_STEP" })}
        >
          {t("back")}
        </button>
        <button
          className="submit-button"
          onClick={handleSubmit}
          disabled={
            !repairLocation.address ||
            !repairLocation.city ||
            !repairLocation.postalCode ||
            !userData.address ||
            !userData.city ||
            !userData.postalCode ||
            !userData.agree
          }
        >
          {t("send")}
        </button>
      </div>
    </div>
  );
};

export default UserDataStep;
