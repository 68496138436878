import React from 'react';

const Terms: React.FC = () => {
  return (
    <div className="container">
      <h1>Conditions d'utilisation</h1>
      <p>Bienvenue sur notre site web. Si vous continuez à naviguer et à utiliser ce site, vous acceptez de vous conformer et d'être lié par les conditions d'utilisation suivantes, qui, avec notre politique de confidentialité, régissent la relation de <b>CarbonRisk Intelligence</b> avec vous par rapport à ce site web. Si vous êtes en désaccord avec une partie quelconque de ces conditions, veuillez ne pas utiliser notre site web.</p>
      
      <h2>Utilisation du site</h2>
      <p>Ce site web peut utiliser des cookies pour surveiller les préférences de navigation. Si vous autorisez l'utilisation de cookies, les informations personnelles suivantes peuvent être stockées par nous pour être utilisées par des tiers.</p>
      
      <h2>Licence d'utilisation</h2>
      <p>Sauf indication contraire, <b>CarbonRisk Intelligence </b> et/ou ses concédants détiennent les droits de propriété intellectuelle pour tout le matériel sur ce site web. Tous les droits de propriété intellectuelle sont réservés. Vous pouvez accéder à ce site web pour votre usage personnel soumis aux restrictions définies dans ces termes et conditions.</p>
      
      <h2>Liens externes</h2>
      <p>Notre site web peut contenir des liens vers d'autres sites web. Nous n'avons aucun contrôle sur la nature, le contenu et la disponibilité de ces sites. L'inclusion de liens n'implique pas nécessairement une recommandation ni ne soutient les vues exprimées en leur sein.</p>
      
      <h2>Modifications des conditions</h2>
      <p><b>CarbonRisk Intelligence</b> peut réviser ces conditions d'utilisation pour son site web à tout moment sans préavis. En utilisant ce site web, vous acceptez d'être lié par la version alors en vigueur de ces conditions d'utilisation.</p>
    </div>
  );
};

export default Terms;
