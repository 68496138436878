import React, { createContext, useContext, useState, ReactNode } from "react";

interface LanguageContextProps {
  language: string;
  setLanguage: (lang: string) => void;
  t: (key: string) => string;
}

interface LanguageProviderProps {
  children: ReactNode;
}

const translations: any = {
  fr: {
    vehicle: "Véhicule",
    brand: "Marque",
    model: "Modèle",
    year: "Date première mise en circulation",
    fuel: "Carburant du véhicule",
    weight: "Masse du véhicule kg (Champ certificat d'immatriculation : G.1)",
    emission:
      "Émissions du véhicule CO2 (Champ certificat d'immatriculation : V.7)",
    parts: "Pièces",
    selectParts: "Sélectionnez les pièces à remplacer / réparer",
    add: "Ajouter",
    next: "Suivant",
    back: "Retour",
    nextPart: "Pièce suivante",
    backPart: "Pièce précédente",
    repairMethod: "Méthodologie",
    overview: "Aperçu",
    agree:
      "J'accepte les <a href='/terms' target='_blank'>Conditions d'utilisation</a> et la <a href='/privacy' target='_blank'>Politique de confidentialité</a>.",
    send: "Envoyer",
    adjust: "Ajuster",
    restart: "Redémarrer",
    selectBrand: "Sélectionnez une marque",
    selectModel: "Sélectionnez un modèle",
    selectedYear: "Sélectionnez une année",
    selectedFuel: "Sélectionnez un carburant",
    selectPart: "Sélectionnez une pièce",
    part: "Pièce",
    repairLocation: "Lieu de réparation",
    locationAddress: "Adresse du lieu",
    locationCity: "Ville",
    locationPostalCode: "Code Postal",
    myAddress: "Mon adresse",
    locate: "Localisation",
    address: "Adresse",
    city: "Ville",
    postalCode: "Code Postal",
    finished: "Terminé",
    result: "Résultat",
    E: "Échange/peinture pièce neuve",
    S: "Echange/peinture pièces de réemploi",
    R3: "Réparation/peinture : déformation importante",
    R2: "Réparation/peinture : déformation faible",
    R1: "Réparation/peinture : rayure",
    DSP3: "Débosselage sans peinture : nombre d'impacts important",
    DSP2: "Débosselage sans peinture : nombre d'impacts moyen",
    DSP1: "Débosselage sans peinture : nombre d'impacts faible",
  },
};

const LanguageContext = createContext<LanguageContextProps>({
  language: "fr",
  setLanguage: () => {},
  t: (key: string) => key,
});

export const LanguageProvider: React.FC<LanguageProviderProps> = ({
  children,
}) => {
  const [language, setLanguage] = useState<string>("fr");

  const t = (key: string) => {
    return translations[language][key] || key;
  };

  return (
    <LanguageContext.Provider value={{ language, setLanguage, t }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
