import React, { ChangeEvent, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../reducers";
import { useLanguage } from "./LanguageContext";
import { TbaseReducer } from "../types/reducersType";

const PartsStep: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useLanguage();

  const { parts, selectedParts } = useSelector(
    (state: TbaseReducer) => state.analysisReducer
  );

  const [selectedPart, setSelectedPart] = useState<string>("");

  const handlePartChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedPart(e.target.value);
  };

  const addPart = () => {
    if (selectedPart && !selectedParts.includes(selectedPart)) {
      dispatch({
        type: "UPDATE_PARTS",
        data: [...selectedParts, selectedPart],
      });
      setSelectedPart("");
    }
  };

  const removePart = (partToRemove: string) => {
    const updatedParts = selectedParts.filter((part) => part !== partToRemove);
    dispatch({
      type: "UPDATE_PARTS",
      data: updatedParts,
    });
  };

  return (
    <div className="form">
      <h2>{t("parts")}</h2>
      <label htmlFor="parts">{t("selectParts")}</label>
      <select id="parts" value={selectedPart} onChange={handlePartChange}>
        <option value="">{t("selectPart")}</option>
        {Object.keys(parts).map((key) => (
          <option key={key} value={key}>
            {parts[key].name}
          </option>
        ))}
      </select>
      <button onClick={addPart} disabled={!selectedPart} className="add-button">
        {t("add")}
      </button>
      <ul className="selected-parts">
        {selectedParts.map((part, index) => (
          <li key={index}>
            {parts[part].name}
            <button onClick={() => removePart(part)} className="remove-button">
              🗑
            </button>
          </li>
        ))}
      </ul>
      <div className="navigation-buttons">
        <button
          className="back-button"
          onClick={() => dispatch({ type: "BACK_STEP" })}
        >
          {t("back")}
        </button>
        <button
          disabled={selectedParts.length === 0}
          className="next-button"
          onClick={() => dispatch({ type: "NEXT_STEP" })}
        >
          {t("next")}
        </button>
      </div>
    </div>
  );
};

export default PartsStep;
