import { combineReducers } from "redux";
import { useDispatch } from "react-redux";

import { analysisReducer } from "./analysisReducer";

import { AppDispatch } from "../store";

const config = { analysisReducer };

const appReducer = combineReducers(config);
export const useAppDispatch: () => AppDispatch = useDispatch;

export default appReducer;
