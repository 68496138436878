import { _fetch } from "../utils/fetch";

export const getBrands = () => {
  return (dispatch: any) => {
    _fetch(
      "/api/analysis/get-brands",
      "GET_BRANDS",
      JSON.stringify({}),
      dispatch
    );
  };
};

export const getModels = (brand: string) => {
  return (dispatch: any) => {
    _fetch(
      `/api/analysis/get-models/${brand}`,
      "GET_MODELS",
      JSON.stringify({}),
      dispatch
    );
  };
};

export const getParts = () => {
  return (dispatch: any) => {
    _fetch(
      "/api/analysis/get-parts",
      "GET_PARTS",
      JSON.stringify({}),
      dispatch
    );
  };
};

export const getResult = (formData: any) => {
  return (dispatch: any) => {
    _fetch(
      "/api/analysis/get-result",
      "GET_RESULT",
      JSON.stringify(formData),
      dispatch,
      "POST"
    );
  };
};
