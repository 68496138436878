import * as React from "react";

interface SvgComponentProps extends React.SVGAttributes<SVGElement> {}

const SvgComponent: React.FC<SvgComponentProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={14}
    fill="none"
    {...props}
  >
    <path
      fill="#40B593"
      d="M19 14s-1.157-3.547-2.99-4.107c0 0-6.586 3.158-11.766-2.473C1.107 4.01.82.98 0 0c0 0 2.17 1.4 4.292 1.727C6.414 2.053 13.55.7 16.252 4.76c0 0 .77 1.073.915 3.313 0 0-1.737-2.583-4.305-3.199-2.598-.622-3.467.333-7.365-.441 0 0 .834.76 5.814.988C13.57 5.525 17.842 8.353 19 14Z"
    />
  </svg>
);

export default SvgComponent;
