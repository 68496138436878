import React from "react";

const Privacy: React.FC = () => {
  return (
    <div className="container">
      <h1>Politique de confidentialité</h1>
      <p>
        Cette politique de confidentialité définit la manière dont <b>CarbonRisk Intelligence </b> utilise et protège les informations que vous donnez lorsque
        vous utilisez ce site web.
      </p>

      <h2>Collecte des informations</h2>
      <p>Nous pouvons collecter les informations suivantes :</p>
      <ul>
        <li>Coordonnées</li>
        <li>
          Informations démographiques telles que l'adresse, le code postal, la ville
        </li>
        <li>
          Autres informations pertinentes pour le fonctionnement du programme (Informations relatives au véhicules)
        </li>
      </ul>

      <h2>Utilisation des informations</h2>
      <p>
        Nous avons besoin de ces informations pour faire fonctionner le programme de calcul
      </p>

      <h2>Sécurité</h2>
      <p>
        Nous nous engageons à garantir la sécurité de vos informations. Afin
        d'empêcher tout accès non autorisé ou divulgation, nous avons mis en
        place des procédures physiques, électroniques et de gestion appropriées
        pour sauvegarder et sécuriser les informations que nous collectons en
        ligne.
      </p>

      <h2>Cookies</h2>
      <p>
        Un cookie est un petit fichier qui demande la permission d'être placé
        sur le disque dur de votre ordinateur. Une fois que vous acceptez, le
        fichier est ajouté et le cookie aide à analyser le trafic web ou vous
        laisse savoir lorsque vous visitez un site particulier. Les cookies
        permettent aux applications web de vous répondre en tant qu'individu.
        L'application web peut adapter ses opérations à vos besoins, goûts et
        dégoûts en collectant et en mémorisant des informations sur vos
        préférences.
      </p>

      <h2>Contrôle de vos informations personnelles</h2>
      <p>
        Vous pouvez choisir de restreindre la collecte ou l'utilisation de vos
        informations personnelles de la manière suivante :
      </p>
      <ul>
        <li>
          Si vous avez précédemment accepté que nous utilisions vos informations
          personnelles à des fins de marketing direct, vous pouvez changer
          d'avis à tout moment en nous écrivant ou en nous envoyant un e-mail à{" "}
          <a href="mailto:contact@carbonriskintelligence.fr">contact@carbonriskintelligence.fr</a>
        </li>
      </ul>

      <h2>Modifications de cette politique</h2>
      <p>
        <b>CarbonRisk Intelligence</b> peut changer cette politique de temps à autre en
        mettant à jour cette page. Vous devriez vérifier cette page de temps en
        temps pour vous assurer que vous êtes satisfait de tout changement.
      </p>
    </div>
  );
};

export default Privacy;
