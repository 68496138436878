import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import VehicleForm from "./components/VehicleForm";
import Terms from "./views/Terms";
import Privacy from "./views/Privacy";

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<VehicleForm />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;
