import React, { ChangeEvent, useEffect } from "react";
import { useSelector } from "react-redux";

import { useLanguage } from "./LanguageContext";

import { useAppDispatch } from "../reducers";

import { getBrands, getModels } from "../actions/analysisAction";

import { TbaseReducer } from "../types/reducersType";

function generateYears(startYear = 1990) {
  const currentYear = new Date().getFullYear(); // Année actuelle
  const years = [];

  for (let year = startYear; year <= currentYear; year++) {
    years.push(year);
  }

  return years.reverse();
}

const fuels = [
  { value: "ES", text: "Essence" }, // ES = Essence
  { value: "GO", text: "Gasoil" }, // GO = Gasoil
  { value: "EE", text: "Électricité" }, // EE = Électricité
  { value: "EH", text: "Hybride Essence" }, // EH = Hybride Essence
  { value: "GL", text: "GPL" }, // GL = GPL (Gaz de Pétrole Liquéfié)
  { value: "GH", text: "Hybride Gasoil" }, // GH = Hybride Gasoil
  { value: "EL", text: "Hydrogène" }, // EL = Hydrogène
];

const VehicleStep: React.FC = () => {
  const dispatch = useAppDispatch();

  const {
    brands,
    models,
    selectedBrand,
    selectedModel,
    selectedYear,
    selectedFuel,
    weight,
    emission,
  } = useSelector((state: TbaseReducer) => state.analysisReducer);

  const { t } = useLanguage();

  const handleBrandChange = (e: ChangeEvent<HTMLSelectElement>) => {
    dispatch({ type: "UPDATE_BRAND", data: e.target.value });
    dispatch({ type: "UPDATE_MODEL", data: "" });
  };

  const handleModelChange = (e: ChangeEvent<HTMLSelectElement>) => {
    dispatch({ type: "UPDATE_MODEL", data: e.target.value });
  };

  const handleYearChange = (e: ChangeEvent<HTMLSelectElement>) => {
    dispatch({ type: "UPDATE_YEAR", data: e.target.value });
  };

  const handleFuelChange = (e: ChangeEvent<HTMLSelectElement>) => {
    dispatch({ type: "UPDATE_FUEL", data: e.target.value });
  };

  useEffect(() => {
    dispatch(getBrands());
  }, [dispatch]);

  useEffect(() => {
    if (selectedBrand) {
      dispatch(getModels(selectedBrand));
    }
  }, [dispatch, selectedBrand]);

  return (
    <div className="form">
      <h2>{t("vehicle")}</h2>
      <label htmlFor="brand">{t("brand")}</label>
      <select id="brand" value={selectedBrand} onChange={handleBrandChange}>
        <option value="">{t("selectBrand")}</option>
        {brands.map((brand, idx: number) => (
          <option key={idx} value={brand}>
            {brand}
          </option>
        ))}
      </select>
      <>
        <label htmlFor="model">{t("model")}</label>
        <select
          disabled={!selectedBrand}
          id="model"
          value={selectedModel}
          onChange={handleModelChange}
        >
          <option value="">{t("selectModel")}</option>
          {models.map((model, idx: number) => (
            <option key={idx} value={model}>
              {model}
            </option>
          ))}
        </select>
      </>
      <>
        <label htmlFor="year">{t("year")}</label>
        <select id="year" value={selectedYear} onChange={handleYearChange}>
          <option value="">{t("selectedYear")}</option>
          {generateYears().map((year, idx) => (
            <option key={idx} value={year}>
              {year}
            </option>
          ))}
        </select>
      </>
      <>
        <label htmlFor="fuel">{t("fuel")}</label>
        <select id="fuel" value={selectedFuel} onChange={handleFuelChange}>
          <option value="">{t("selectedFuel")}</option>
          {fuels.map((fuel, idx) => (
            <option key={idx} value={fuel.value}>
              {fuel.text}
            </option>
          ))}
        </select>
      </>
      <>
        <label htmlFor="weight">{t("weight")}</label>
        <input
          disabled={!selectedModel}
          type="text"
          id="weight"
          name="postalCode"
          value={weight}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            dispatch({ type: "UPDATE_WEIGHT", data: e.target.value })
          }
        />
        <label htmlFor="emission">{t("emission")}</label>
        <input
          disabled={!selectedModel}
          type="text"
          id="emission"
          name="postalCode"
          value={emission}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            dispatch({ type: "UPDATE_EMISSION", data: e.target.value })
          }
        />
      </>
      <div className="navigation-buttons">
        <button
          disabled={!selectedBrand || !selectedModel || !weight || !emission}
          className="next-button"
          onClick={() => dispatch({ type: "NEXT_STEP" })}
        >
          {t("next")}
        </button>
      </div>
    </div>
  );
};

export default VehicleStep;
